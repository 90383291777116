import { useEffect, useCallback, useState } from "react";
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import Circle from 'components/markup/loading/Circle'

import Signature from './Signature'

import Preview from './Preview'
import Download from './Download'
import Header from './Header'
import Error from './Error'
import IsSigned from './IsSigned'
import Void from './Void'
import NewLinkSent from './NewLinkSent'

import Password from './Password'

import Axios from 'axios';
import keys from 'keys';

import deviceInfo from 'utils/deviceInfo'

import api from 'api';

import AcceptDisclosures from "./Modals/AcceptDisclosures";
import Terms from "./Modals/Terms";

const Sign = ({match}) => {

    const [baseRequest] = useState({
        responseType:'arraybuffer',
        headers: { authorization: `Bearer ${keys.SYSTEM_API_KEY}` },
        withCredentials: true,
    });

    const [showTerms, setShowTerms] = useState(false)
    const [showAcceptDisclosures, setShowAcceptDisclosures] = useState(false)

    const toggleModalTerms = useCallback(() => {
        setShowAcceptDisclosures(!showAcceptDisclosures)
        setShowTerms(!showTerms)
    }, [showAcceptDisclosures, showTerms])


    const [err, setErr] = useState(null);
    const [showNewLink, setShowNewLink] = useState(false);
    const [isVoid, setIsVoid] = useState(false);
    const [isSigned, setIsSigned] = useState(false);
    const [previewPDFData, setPreviewPDFData] = useState(null);
    const [signedPDFData, setSignedPDFData] = useState(null);
    const [pdfData, setPDFData] = useState(null);
    const [signingDocument, setSigningDocument] = useState(null);
    const [signState, setSignState] = useState({
        signature : null,
        initials  : null,
        markers   : null,
    });

    const onPasswordSucceeded = useCallback(async (_pdfData) => {
        setPDFData(_pdfData.data.data)
    }, []);

    const validatePassword = useCallback(async (_signingDocument, password, cb) => {
        const validated = await api.signing_documents.password(match.params.party_id, { password: password })
        if(validated.message) return cb(validated.message[0])

        cb(null, validated.data)
    }, [match.params.party_id])

    const onPreview = useCallback(async (data) => {
        toggleStandardLoader(true);

        const preview = await api.signing_documents.preview(signingDocument._id, {
            party_id  : match.params.party_id,
            signature : data.signature,
            initials  : data.initials,
            markers   : data.markers,
        })

        toggleStandardLoader(false);
        if(preview.message) return toggleAlertBS(true, `Something's not right, please try again`);

        api.signing_events.create({
            party_id             : match.params.party_id, 
            event               : 'Finalized Document With Fields And Signature'
        })
        setPreviewPDFData(preview.data.data)
        setSignState({
            signature : data.signature,
            initials  : data.initials,
            markers   : data.markers,
        })
    }, [signingDocument, match.params.party_id])

    const onSign = useCallback(async () => {
        try {
            toggleStandardLoader(true);
            const result = await Axios({
                ...baseRequest,
                method: 'post',
                url: keys.API_URL + `/v1/signing/documents/${signingDocument._id}/sign`,
                data: { 
                    party_id            : match.params.party_id,
                    document_object_key : signingDocument.document_object_key,
                    signature           : signState.signature,
                    initials            : signState.initials ? signState.initials : undefined,
                    markers             : signState.markers,
                    device              : deviceInfo.init(),
                },
            })
            toggleStandardLoader(false);
            if(result.data.message) return toggleAlertBS(true, `Something's not right, please try again`);


            setSignedPDFData(result.data)

       } catch(e) {
           console.log(e)
           toggleStandardLoader(false);
           return toggleAlertBS(true, `Something's not right, please try again`);
       }

    }, [signingDocument, signState, baseRequest, match.params.party_id])

    const fetchDocument = useCallback(async () => {
        const party_id = match.params.party_id

        const templateDocSent = await api.signing_documents.findById(party_id);

        if(!templateDocSent.data) {
            if(templateDocSent.message.includes('To protect the security of this document a new link was sent to either the phone number or email address on file. Please use that link to access this document.')) {
                return setShowNewLink(true)
            } else {
                return setErr(true)
            }
        }
        const contactParty = templateDocSent.data.contactParty;

        if(templateDocSent.data.status === 'signed' || contactParty.signed_on) return setIsSigned(true);
        if(templateDocSent.data.status === 'void') return setIsVoid(true);

        if(!contactParty.requiresPassword) {
            const validated = await api.signing_documents.password(party_id, { password: '' })
            if(validated.message)  return toggleAlertBS(true, `This document is unable to be signed at this time.`);

            onPasswordSucceeded(validated)
        }

        api.signing_events.create({
            party_id    : party_id,
            event       : 'Opened Document'
        })

        setSigningDocument(templateDocSent.data)

    }, [match.params, onPasswordSucceeded])

    useEffect(() => {
        fetchDocument();
    }, [fetchDocument])

    if(err) return <Error />
    if(isVoid) return <Void />
    if(isSigned) return <IsSigned />
    if(showNewLink) return <NewLinkSent />

    if(!signingDocument) return <div className="py-6"><Circle /></div>

    if(signingDocument.contactParty.requiresPassword && !pdfData) return (
        <Password
            signingDocument={signingDocument} 
            onPasswordSucceeded={onPasswordSucceeded}
            validatePassword={validatePassword}
        />
    )

    if(signedPDFData) return (
        <Download 
            signingDocument={signingDocument}
            signedPDFData={signedPDFData}
            party_id={match.params.party_id}
        />
    )

    return (
        <div>
            <Header 
                signingDocument={signingDocument}
            />

            {previewPDFData ? (
                <Preview 
                    signingDocument={signingDocument}
                    allMarkers={signingDocument.markers}
                    pdfData={previewPDFData}
                    onPreview={onPreview}
                    onSign={onSign}
                    party_id={match.params.party_id}
                />
            ) : pdfData ? (
                <div>
                    <Signature 
                        setShowAcceptDisclosures={setShowAcceptDisclosures}
                        signingDocument={signingDocument}
                        allMarkers={signingDocument.markers}
                        pdfData={pdfData}
                        onPreview={onPreview}
                        party_id={match.params.party_id}
                    />

                    <AcceptDisclosures 
                        showModal={showAcceptDisclosures}
                        toggleModal={() => setShowAcceptDisclosures(!showAcceptDisclosures)}
                        toggleModalTerms={toggleModalTerms}
                        party_id={match.params.party_id}
                    />

                    <Terms 
                        showModal={showTerms}
                        toggleModalTerms={toggleModalTerms}
                    />

                </div>
            ) : <div className="py-8"><Circle /></div>}
        </div>
    )

}

export default Sign
