
import PropTypes from 'prop-types';
import React from "react";
import SignatureCanvas from 'react-signature-canvas';
import { FormGroup, Modal } from "reactstrap";
import api from 'api'

class SignModalAddInitials extends React.Component {

    state = {
        name: {}
    }

    clearSigPad = () => {
        this.props.setInitials(null, null);
        this.sigPadInitials.clear()
    }

    resizeCanvas = () => {

        const box = document.getElementById('initialsBox')

        if(box) {
            const width = box.offsetWidth
            const height = width / 3

            this.setState({width, height})

            let initials64 = this.sigPadInitials.getTrimmedCanvas()

            document.querySelector('.sigIntials').width = width
            document.querySelector('.sigIntials').height = height

            this.sigPadInitials.fromDataURL(initials64.toDataURL('image/png'))       
        }

    }

    onConfirm = () => {
        const initials = this.sigPadInitials.getTrimmedCanvas().toDataURL('image/png')

        var image = new Image(); 
        image.src = initials; 

        image.onload = () => {
            if(initials.length > 1000) {
                this.props.setInitials(initials, image.width, image.height);
            }
            api.signing_events.create({
                party_id             : this.props.party_id, 
                event               : 'Confirmed Initials'
            })
            this.props.toggleModalAddInitials()
        };

    }

    componentDidMount = () => {
        window.addEventListener('resize', this.resizeCanvas)
        this.resizeCanvas();
    }

    render() {

        const { width, height } = this.state;
        const { showModalAddInitials, toggleModalAddInitials, initials } = this.props;

        return (

            <Modal
                className="modal-dialog-centered modal-add-initials"
                isOpen={showModalAddInitials}
                toggle={() => {}}
                size="lg"
                fade={false}
                zIndex={999999999999}
            >

                <div className="modal-header">
                    <h5 className="modal-title">Add initials</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalAddInitials}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    {initials ? (
                        <p className="text-sm">Use the box below to add or update your initials for this document.</p>
                    ) : (
                        <div>
                            <p className="text-sm">Let's get started adding your initials. Don't worry adding it here will not sign the document and you can change it at any time.</p>
                            <p className="text-sm mb-0">Simply use your finger (mobile) or click and drag your mouse in the box below.</p>
                        </div>
                    )}

                </div>
                
                <div className="modal-body bg-secondary initials-body">
                    <FormGroup>
                        <div className="initials-box" id="initialsBox">
                            <SignatureCanvas
                                width={width}
                                height={height}
                                clearOnResize={false}
                                backgroundColor="rgba(255,255,255,.0)"
                                onEnd={() => this.setState({initialsErrors: false})}
                                penColor='black'
                                throttle={4}
                                maxWidth={3}
                                minDistance={3}
                                canvasProps={{className: 'sigIntials' }}
                                ref={(ref) => { this.sigPadInitials = ref }}
                            />
                        </div>
                    </FormGroup>
                </div>

                <div className="modal-footer">
                    <button onClick={this.clearSigPad} className="btn btn-outline-danger" >Clear Initials</button>
                    <button onClick={this.onConfirm} className="btn btn-success" >Next</button>
                </div>

            </Modal>

        );
    }
}

SignModalAddInitials.propTypes = {
    showModalAddInitials    : PropTypes.bool.isRequired,
    toggleModalAddInitials  : PropTypes.func.isRequired,
    setInitials             : PropTypes.func.isRequired,
    initials                : PropTypes.string,
}

export default SignModalAddInitials
