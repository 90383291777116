
import Sign from '../pages/Sign';

const routes = [


    {
        path: "/:party_id",
        component: Sign,
    },
  
    {
        path: "/",
        component: Sign,
    },
  
]

export default routes;